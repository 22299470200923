<template>
    <div style="background-color:#ffffff;">
        <van-nav-bar title="流水详情" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="water_title">
            <div>ID： {{ u_id }}</div>
            <div>昵称： {{ nickname }}</div>
        </div>
        <div class="list">
            <div class="noData" v-if="list.length == 0">
                <img src="img/noData.png" alt="">
            </div>
            <div class="water_text">
                <div>经典礼物</div>
                <div class="text_right">{{ list.price_1 }}</div>
            </div>
            <div class="water_text">
                <div>背包85</div>
                <div class="text_right">{{ list.price_285 }}</div>
            </div>
            <div class="water_text">
                <div>背包其他</div>
                <div class="text_right">{{ list.price_265 }}</div>
            </div>
            <!-- <div class="water_text">
                <div>聊天礼物</div>
                <div class="text_right">{{list.price_3}}</div>
            </div>
            <div class="water_text">
                <div>幸运礼物</div>
                <div class="text_right">{{list.price_5}}</div>
            </div> -->
            <div class="water_text">
                <div>组队开黑</div>
                <div class="text_right">{{ list.game_value }}</div>
            </div>
            <div class="water_text">
                <div>汇总</div>
                <div class="text_right">{{ list.total }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { UserWaterInfo } from "@/api/api";
export default {
    data() {
        return {
            type: null,
            item: null,
            union_id: null,
            receive_user_id: null,
            nickname: '',
            u_id: '',
            list: {}

        };
    },
    created() {
        this.type = this.$route.query.type
        this.item = this.$route.query.item
        this.union_id = this.$route.query.union_id
        this.u_id = this.$route.query.u_id
        this.nickname = this.$route.query.name
        this.receive_user_id = this.$route.query.receive_user_id
        this.getUserWaterInfo()
    },
    methods: {
        getUserWaterInfo() {
            let param = {
                type: this.type,
                union_id: this.union_id,
                item: this.item,
                receive_user_id: this.receive_user_id
            }

            UserWaterInfo(param).then(res => {
                console.log(res)
                this.list = res.data

            })
        },
        onClickLeft() {
            this.$router.back()
        }
    }
};
</script>
<style scoped>
.water_title {
    max-width: 100%;
    padding: 0 30px 0 30px;
    display: flex;
    height: 80px;
    line-height: 80px;
    background-color: #f2f2f2;
    font-size: 26px;
}

.water_title div {
    flex: 1;
    text-align: center;
}

.list {
    font-size: 24px;
}

.water_text {
    max-width: 100%;
    padding: 0 30px 0 30px;
    display: flex;
    height: 80px;
    line-height: 80px;
    background-color: #ffffff;
    font-size: 26px;
    display: flex;
    border-bottom: 1px solid #f2f2f2;
}

.water_text div {
    flex: 1;
}

.text_right {
    text-align: right;
}
</style>>

</style>